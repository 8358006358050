<template>
  <q-dialog
    ref="dialogRef"
    v-model="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    :maximized="$q.platform.is.mobile ? maximizedToggle : false"
    style="background: rgba(0, 0, 0, 0)"
    @hide="clearInputs()"
  >
    <!-- Formulario del edit personal address -->
    <q-card class="text-left q-ma-none q-pa-lg" :style="{ 'font-family': settings.typography }">
      <CloseButton />
        <q-form @submit="editPersonalAddress" class="q-gutter-md">
            <q-item class="q-ma-sm q-pa-none">
              <q-item-section>
                <q-item-label class="text-h6">
                  <!-- {{ $t("Profile.personal-address.my-address") }}{{ $t("Profile.personal-address.my-address") }} -->
                  <div v-if="addressType=='shipping'">
                    {{ $t("Profile.personal-address.add-shipping-address") }}
                  </div>
                  <div v-else>
                    {{ $t("Profile.personal-address.add-billing-address") }}
                  </div>
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item class="q-mx-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-address.address")
                }}</q-item-label>
                <q-input
                  filled
                  v-model="inputAddress.Address"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t('Profile.personal-address.please-introduce-address'),
                  ]"
                  lazy-rules
                  maxlength="200"
                />
              </q-item-section>
            </q-item>

            <q-item class="q-ma-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-address.postalcode")
                }}</q-item-label>
                <q-input
                  filled
                  v-model="inputAddress.PostalCode"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t(
                        'Profile.personal-address.please-introduce-postalcode'
                      ),
                  ]"
                  lazy-rules
                  maxlength="30"
                />
              </q-item-section>
            </q-item>

            <q-item class="q-ma-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-address.city")
                }}</q-item-label>
                <q-input
                  filled
                  v-model="inputAddress.City"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t('Profile.personal-address.please-introduce-city'),
                  ]"
                  lazy-rules
                  maxlength="30"
                />
              </q-item-section>
            </q-item>

            <q-item class="q-ma-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-address.province")
                }}</q-item-label>
                <q-input
                  filled
                  v-model="inputAddress.Province"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t('Profile.personal-address.please-introduce-province'),
                  ]"
                  lazy-rules
                  maxlength="30"
                />
              </q-item-section>
            </q-item>

            <q-item class="q-ma-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-address.country")
                }}</q-item-label>
                <!-- <q-input
                  filled
                  v-model="inputAddress.Country"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t('Profile.personal-address.please-introduce-country'),
                  ]"
                  lazy-rules
                  maxlength="30"
                /> -->
                <q-select
                  filled
                  v-model="inputAddress.Country"
                  :options="inputAddress.CountryOptions"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t('Profile.personal-address.please-introduce-country'),
                  ]"
                  lazy-rules
                />
              </q-item-section>
            </q-item>
          <div class="row justify-center">
            <q-btn
              class="button-blue"
              :label="$t('General.save')"
              type="submit"
              :style="{ background: settings.color_primary }"
            >
            </q-btn>
          </div>
        </q-form>
      </q-card>

    <WaitEdit v-model="waitEditDialog" :settings="settings" />
    <!-- <SuccessEdit v-model="successEditDialog" :settings="settings" /> -->
    <FailEdit v-model="failEditDialog" :settings="settings" />
  </q-dialog>
</template>

<script>
import { ref } from "vue";
import { defineAsyncComponent } from "vue";
import { mapActions, mapState } from "vuex";
import api from "../../../../services/Api";
import settings from "@/mixins/settings";
import { countryList, getCountryCodeFromName } from "@/services/helpers/CountryList";

const WaitEdit = defineAsyncComponent(() => import("./dialogs/WaitEdit.vue"));

const SuccessEdit = defineAsyncComponent(() =>
  import("./dialogs/SuccessEdit.vue")
);

const FailEdit = defineAsyncComponent(() => import("./dialogs/FailEdit.vue"));

const CloseButton = defineAsyncComponent(() =>
  import("../../../shared/buttons/CloseButton.vue")
);

export default {
  mixins: [settings],

  components: {
    CloseButton,
    SuccessEdit,
    FailEdit,
    WaitEdit,
  },
  props: {
    dialog: {
      default: false,
    },
    addressType: {
      default: "shipping",
    },
  },
  emits: ['update:dialog'],
  data() {
    return {
      inputAddress: {
        Address: "",
        PostalCode: "",
        City: "",
        Province: "",
        Country: "",
        CountryOptions: countryList
      },
      waitEditDialog: false,
      loading: false,
      successEditDialog: false,
      failEditDialog: false,
    };
  },
  computed: {
    settings() {
      return this.$store.state.settings.settings;
    },

    ...mapState("auth", ["user"]),
  },
  methods: {
    // Función que cierra el diálogo
    close() {
      this.dialogRef.hide();
    },
    
    clearInputs() {
      this.inputAddress.Address = "";
      this.inputAddress.PostalCode = "";
      this.inputAddress.City = "";
      this.inputAddress.Province = "";
      this.inputAddress.Country = "";
    },

    ...mapActions("settings", ["getSettings"]),

    ...mapActions({ setUser: "auth/setUser" }),

    ...mapActions("addresses", ["getAddresses"]),

    editPersonalAddress() {
      this.waitEditDialog = true;
      setTimeout(() => {
        this.onSubmitData();
      }, 3000);
    },

    // Funcion para guardar cambios. Primero hace un put concatenando los inputs y luego se hace un get para actualizar la información de usuario
    onSubmitData() {
      api.post("front-catalog/customers/addresses", {
          type: this.addressType,
          address: this.inputAddress.Address,
          city: this.inputAddress.City,
          province: this.inputAddress.Province,
          postal_code: this.inputAddress.PostalCode,
          country: getCountryCodeFromName(this.inputAddress.Country),
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.failEditDialog = true;
        })
        .finally(() => {
          this.waitEditDialog = false;
          this.successEditDialog = true;
          this.getAddresses();
          this.close();
        });
    },
  },
  setup() {
    return {
      dialog: ref(false),
      dialogRef: ref(),
      maximizedToggle: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    left: 26%;
    max-width: 560px;
    min-height: 610px;
    min-width: 466px;
    position: relative;
    top: 20px;
  }
}

.button-blue {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 30px;
  text-transform: capitalize;
  width: 200px;
  color: white;
}

.q-card {
  background-color: $gray;
  border-radius: 25px 25px 25px 0px;
}

/*.q-field--filled .q-field__control {
  height: 53px !important;
}*/

</style>
